import { Link, useNavigate } from "react-router-dom"
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {apiUrl} from '../../config'
import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, Accept';

var google_login_client_id = "209904001034-2ceicghtcgvovulsg32pkbueeq1uu0qt.apps.googleusercontent.com";

function Login() {
  let navigate = useNavigate();


  function postApi(params){
    return new Promise(function(myResolve, myReject) {
        axios.post(apiUrl+'/google-login',params).then(function (response) {
            if(response.status==200){
                myResolve(response.data);
            }else{
                myResolve(null);
            }
      })
      .catch(function (error) {
        console.log(error);
        myResolve(null);
      });
 });
    
}

  async function responseGoogle(response) {
    var token = await postApi({credential:response.credential});
    console.log(token)
  }

  return (
    <div className="app" id="app">
      {/* <!-- ############ LAYOUT START--> */}
      <div className="center-block w-xxl w-auto-xs p-y-md">
        <div className="navbar">
          <div className="pull-center">
            <i className="fa fa-bolt fa-4x"></i>
          </div>
        </div>
        <div className="p-a-md box-color r box-shadow-z1 text-color m-a">
          <div className="m-b text-sm">
            Sign in with your Flatkit Account
          </div>
          <form name="form">
            <div className="md-form-group float-label">
              <input type="email" className="md-input" ng-model="user.email" required />
              <label>Email</label>
            </div>
            <div className="md-form-group float-label">
              <input type="password" className="md-input" ng-model="user.password" required />
              <label>Password</label>
            </div>
            <div className="m-b-md">
              <label className="md-check">
                <input type="checkbox" /><i className="primary"></i> Keep me signed in
              </label>
            </div>
            <button type="submit" className="btn primary btn-block p-x-md">Sign in</button>
            <Link to="/dashboard" className="btn primary btn-block p-x-md">Dashboard Sign in</Link>
            <br/>
            <GoogleOAuthProvider clientId={google_login_client_id}>
              <GoogleLogin
                onSuccess={credentialResponse => {
                  responseGoogle(credentialResponse);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </GoogleOAuthProvider>
          </form>
        </div>

        <div className="p-v-lg text-center">


          <div className="m-b"><a ui-sref="access.forgot-password" href="forgot-password.html" className="text-primary _600">Forgot password?</a></div>
          <div>Do not have an account? <a ui-sref="access.signup" href="signup.html" className="text-primary _600">Sign up</a></div>
        </div>
      </div>

      {/* <!-- ############ LAYOUT END--> */}

    </div>
  );
}

export default Login;
