// eslint-disable react/jsx-source-props-no-spreading

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/animate.css/animate.min.css'
import './assets/glyphicons/glyphicons.css'
import './assets/font-awesome/css/font-awesome.min.css'
import './assets/material-design-icons/material-design-icons.css'
import './assets/bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/app.min.css'
import './assets/styles/font.css'
import { createBrowserRouter,RouterProvider} from "react-router-dom";

import AdminLogin from './pages/admin/Login';


const router = createBrowserRouter([
  {path: "/",element: <AdminLogin/>},
  {path: "/login",element: <AdminLogin/>},
  {path: "/dashboard",element: <App/>},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
  // <React.StrictMode>
   
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
