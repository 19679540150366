import logo from './logo.svg';
import './App.css';
import AdminLayout from './layouts/AdminLayout';

function App() {
  return (
    <AdminLayout></AdminLayout>
  );
}

export default App;
